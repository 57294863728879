import React, {useEffect, useRef, useState} from 'react';
import {FaFileAlt, FaFilePdf, FaFileWord} from 'react-icons/fa';
import {AttachmentResponse} from "../../types/types";
import {RootState} from "../../../redux";
import {useSelector} from "react-redux";

interface FileInfoProps {
    fileInfo: AttachmentResponse | null;
    fileBlob: Blob | null;
    fileUrl: string | null;
}

const FileInfo: React.FC<FileInfoProps> = ({fileInfo, fileBlob, fileUrl}) => {
    const transcribeAudioMessages = useSelector((state: RootState) => state.inbox.transcribeAudioMessages);

    const [transcript, setTranscript] = useState<string | null>(null);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null); // Реф для аудиоплеера

    let recognition: any;

    // Функция для запуска транскрибации аудиофайла через Web Audio API и Web Speech API
    const startTranscription = () => {
        setError(null);
        setTranscript(null);
        setIsTranscribing(true);

        if (!('webkitSpeechRecognition' in window)) {
            setError('Ваш браузер не поддерживает Web Speech API.');
            setIsTranscribing(false);
            return;
        }

        recognition = new (window as any).webkitSpeechRecognition();
        recognition.lang = 'ru-RU';
        // recognition.continuous = true; // Делаем континуальный режим ломает распознавание
        recognition.interimResults = false;

        console.log('Starting speech recognition'); // Логируем начало распознавания

        recognition.onresult = (event: any) => {
            let finalTranscript = '';
            console.log('onresult event:', event); // Логируем событие для отладки

            for (let i = 0; i < event.results.length; i++) {
                const result = event.results[i];
                if (result.isFinal) {
                    finalTranscript += result[0].transcript;
                }
            }

            console.log('Final transcript:', finalTranscript); // Логируем итоговый транскрипт
            setTranscript(finalTranscript);
        };

        recognition.onerror = (event: any) => {
            console.error('Speech recognition error:', event.error); // Логируем ошибку
            setError(`Ошибка транскрибации: ${event.error}`);
            setIsTranscribing(false);
        };

        recognition.onend = () => {
            console.log('Speech recognition ended'); // Логируем завершение распознавания
            setIsTranscribing(false);
        };

        recognition.start(); // Запускаем распознавание
    };


    // Функция для остановки транскрибации
    const stopTranscription = () => {
        if (recognition) {
            recognition.stop(); // Останавливаем распознавание
        }
        setIsTranscribing(false);
    };

    useEffect(() => {
        const audioElement = audioRef.current;

        if (audioElement && transcribeAudioMessages === 'true') {
            const handlePlay = () => {
                startTranscription(); // Начинаем транскрибацию при нажатии Play
            };

            const handlePauseOrEnded = () => {
                stopTranscription(); // Останавливаем транскрибацию при паузе или завершении
            };

            audioElement.addEventListener('play', handlePlay);
            audioElement.addEventListener('pause', handlePauseOrEnded);
            audioElement.addEventListener('ended', handlePauseOrEnded);

            return () => {
                audioElement.removeEventListener('play', handlePlay);
                audioElement.removeEventListener('pause', handlePauseOrEnded);
                audioElement.removeEventListener('ended', handlePauseOrEnded);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUrl, transcribeAudioMessages]);

    if (!fileInfo || !fileBlob || !fileUrl) return null;

    const renderFilePreview = () => {
        if (fileInfo.contentType.startsWith('image/')) {
            return (
                <div className="mt-2">
                    <img
                        src={fileUrl}
                        alt="attachment"
                        className="max-w-full max-h-60 rounded-lg shadow-md cursor-pointer"
                        onClick={() => window.open(fileUrl, '_blank')}
                    />
                </div>
            );
        } else if (fileInfo.contentType === 'application/pdf') {
            return <FaFilePdf className="text-red-500 text-4xl cursor-pointer"
                              onClick={() => window.open(fileUrl, '_blank')}/>;
        } else if (fileInfo.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return <FaFileWord className="text-blue-500 text-4xl cursor-pointer"
                               onClick={() => window.open(fileUrl, '_blank')}/>;
        } else if (fileInfo.contentType.startsWith('audio/')) {
            return (
                <div className="w-56 bg-gray-100 rounded-xl shadow-md flex flex-col items-center space-y-3">
                    <audio ref={audioRef} controls className="w-full rounded-lg focus:outline-none">
                        <source src={fileUrl} type={fileInfo.contentType}/>
                        <source src={fileUrl.replace('.oga', '.mp3')} type="audio/mpeg"/>
                        Your browser does not support the audio element.
                    </audio>
                    {transcribeAudioMessages === 'true' && (
                        <>
                            {isTranscribing ? (
                                <div className="text-xs text-gray-500">Транскрибация аудио...</div>
                            ) : error ? (
                                <div className="p-2 bg-red-100 text-red-600 rounded-lg shadow-inner max-w-full text-sm">
                                    <strong>Ошибка:</strong> {error}
                                </div>
                            ) : transcript ? (
                                <div className="p-2 bg-gray-200 rounded-lg shadow-inner max-w-full text-sm">
                                    <strong>Транскрипт:</strong> {transcript}
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            );
        } else {
            return <FaFileAlt className="text-slate-500 text-4xl cursor-pointer"
                              onClick={() => window.open(fileUrl, '_blank')}/>;
        }
    };

    if (fileInfo?.size) {
        const fileSizeMB = (fileInfo.size / (1024 * 1024)).toFixed(2);
        return (
            <div className="flex items-center mt-2 space-x-2">
                <div>{renderFilePreview()}</div>
                <div className="text-sm">
                    <div className="text-xs text-slate-500 break-all">{fileInfo.name}</div>
                    <div className="text-xs text-slate-500">{fileSizeMB} MB</div>
                </div>
            </div>
        );
    }
    return null;
};

export default FileInfo;
