import instance from './base-api';
import {
    Connection,
    FindSingleChatsParams,
    MessageInterface,
    SingleAndMultiChatIdsRequest
} from "../components/types/types";

// Общий базовый URL для всех запросов
const apiUrl = '/client-ws';

// Whatsapp Channel Resource
const clientWsWhatsappChannel = {
    get: (channelId: string) => instance.get(`${apiUrl}/api/whatsapp-channels/${channelId}`),
    update: (channelId: string, channelDto: Connection) => instance.put(`${apiUrl}/api/whatsapp-channels/${channelId}`, channelDto),
    delete: (channelDto: Connection) => instance.delete(`${apiUrl}/api/whatsapp-channels/${channelDto.channelId}`),
    create: () => instance.post(`${apiUrl}/api/whatsapp-channels`, {}),
    syncContactsAndMessages: (channelId: string) => instance.post(`${apiUrl}/api/whatsapp-channels/${channelId}/sync`, {}),
    sendMessage: (channelId: string, messageDto: MessageInterface) => instance.post(`${apiUrl}/api/whatsapp-channels/${channelId}/send-message`, messageDto),
    init: (channelId: string) => instance.post(`${apiUrl}/api/whatsapp-channels/${channelId}/init`, {}),
    disable: (channelDto: Connection) => instance.post(`${apiUrl}/api/whatsapp-channels/${channelDto.channelId}/disable`, channelDto),
    getAuthQrCode: (channelId: string) => instance.get(`${apiUrl}/api/whatsapp-channels/${channelId}/qr-code`),
};

// Telegram Channel Resource
const clientWsTelegramChannel = {
    get: (channelId: string) => instance.get(`${apiUrl}/api/telegram-channels/${channelId}`),
    update: (channelId: string, channelDto: Connection) => instance.put(`${apiUrl}/api/telegram-channels/${channelId}`, channelDto),
    delete: (channelDto: Connection) => instance.delete(`${apiUrl}/api/telegram-channels/${channelDto.channelId}`),
    create: () => instance.post(`${apiUrl}/api/telegram-channels`, {}),
    syncContactsAndMessages: (channelId: string) => instance.post(`${apiUrl}/api/telegram-channels/${channelId}/sync`, {}),
    sendMessage: (channelId: string, messageDto: MessageInterface) => instance.post(`${apiUrl}/api/telegram-channels/${channelId}/send-message`, messageDto),
    init: (channelId: string) => instance.post(`${apiUrl}/api/telegram-channels/${channelId}/init`, {}),
    disable: (channelDto: Connection) => instance.post(`${apiUrl}/api/telegram-channels/${channelDto.channelId}/disable`, channelDto),
    getAuthQrCode: (channelId: string) => instance.get(`${apiUrl}/api/telegram-channels/${channelId}/qr-code`),
};

// Billing plan resource
const clientWsBillingPlan = {
    upgradePlanNow: (billingPlanType: string) => instance.put(`${apiUrl}/api/billing-plan/upgrade-now/${billingPlanType}`),
    downgradePlanInNextCycle: (billingPlanType: string) => instance.put(`${apiUrl}/api/billing-plan/downgrade-in-next-cycle/${billingPlanType}`),
    getCurrentBillingPlan: () => instance.get(`${apiUrl}/api/billing-plan/current-plan`),
    getAvailableBillingPlans: () => instance.get(`${apiUrl}/api/billing-plan/available-plans`),
};


// Avito Channel Resource
const clientWsAvitoChannel = {
    get: (channelId: string) => instance.get(`${apiUrl}/api/avito-channels/${channelId}`),
    update: (channelId: string, channelDto: Connection) => instance.put(`${apiUrl}/api/avito-channels/${channelId}`, channelDto),
    delete: (channelDto: Connection) => instance.delete(`${apiUrl}/api/avito-channels/${channelDto.channelId}`),
    create: () => instance.post(`${apiUrl}/api/avito-channels`, {}),
    syncContactsAndMessages: (channelId: string) => instance.post(`${apiUrl}/api/avito-channels/${channelId}/sync`, {}),
    sendMessage: (channelId: string, messageDto: MessageInterface) => instance.post(`${apiUrl}/api/avito-channels/${channelId}/send-message`, messageDto),
    init: (channelId: string) => instance.post(`${apiUrl}/api/avito-channels/${channelId}/init`, {}),
    disable: (channelDto: Connection) => instance.post(`${apiUrl}/api/avito-channels/${channelDto.channelId}/disable`, channelDto),
    getAuthUrl: (channelId: string) => instance.get(`${apiUrl}/api/avito-channels/${channelId}/login-url`),
    getAuthRedirectUrl: (channelId: string) => instance.get(`${apiUrl}/api/avito-channels/${channelId}/login-url-redirect`),
};

// User Profile Resource
const clientWsUserProfile = {
    load: () => instance.get(`${apiUrl}/api/user-profile`),
    updateSettingsProperty: (settings: any) => instance.put(`${apiUrl}/api/user-profile/settings-property`, settings),
    updateUserProfileSettings: (profileSettings: any) => instance.put(`${apiUrl}/api/user-profile/settings`, profileSettings),
}

// User Invitation Resource
const clientWsUserInvitation = {
    create: () => instance.post(`${apiUrl}/api/user/invitation/create-invite-code`),
    get: () => instance.get(`${apiUrl}/api/user/invitation/all`),
    delete: (inviteCode: string) => instance.delete(`${apiUrl}/api/user/invitation/delete/${inviteCode}`),
};

// Content Upload Resource
const clientWsContentUpload = {
    upload: (fileData: FormData) => {
        return instance.post(`${apiUrl}/api/upload/upload`, fileData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};

// Multi Users Resource
const clientWsMultiUsers = {
    get: () => instance.get(`${apiUrl}/api/user/multi/all`),
};

// Multi Chat Resource
const clientWsMultiChatResource = {
    findSingleChats: (params: FindSingleChatsParams = {size: 50, request: {}}) =>
        instance.post(`${apiUrl}/api/multi-chats/find-single-chat`, params.request),

    attachSingleChatToChat: (data: SingleAndMultiChatIdsRequest) =>
        instance.post(`${apiUrl}/api/multi-chats/attach`, data),

    detachSingleChat: (data: SingleAndMultiChatIdsRequest) =>
        instance.post(`${apiUrl}/api/multi-chats/detach`, data),
};


// Message Operation Resource
const clientWsMessageOperate = {
    markAsRead: (messageId: string) => instance.post(`${apiUrl}/api/messages-operate/${messageId}/mark-as-read`),
    markAsUnread: (messageId: string) => instance.post(`${apiUrl}/api/messages-operate/${messageId}/mark-as-unread`),
};

// Invoice Resource
const clientWsInvoiceResource = {
    createInvoice: (billingPlanType: string) => instance.post(`${apiUrl}/api/invoice/${billingPlanType}`),
};

// Messages and Chats Resource
const clientWsChat = {
        findChats: (page: number = 0, size: number = 50, channelId?: string, onlyUnread?: boolean) =>
            instance.get(`${apiUrl}/api/chats?page=${page}&size=${size}${channelId ? `&channelId=${encodeURIComponent(channelId)}` : ''}${onlyUnread ? `&onlyUnread=true` : ''}`),
        findChatById: (chatId: string = '') => instance.get(`${apiUrl}/api/chats/${chatId}`),
        markAsRead:
            (chatId: string) => instance.post(`${apiUrl}/api/chats/${chatId}/mark-as-read`),
    }
;

// Messages Resource
const clientWsMessage = {
    findInChat: (chatId: string, page: number = 0, size: number = 50) => instance.get(`${apiUrl}/api/messages/${chatId}?page=${page}&size=${size}`),
    findInChats: (chatIds: string[], page: number = 0, size: number = 50) =>
        instance.get(`${apiUrl}/api/messages/in-chats`, {
            params: {
                chatId: chatIds, // Axios автоматически конвертирует массив в `chatId=value1&chatId=value2`
                page,
                size
            }
        }),
};

// Content-resource
const clientWsContent = {
    findFilesByIds: (ids: string[]) => instance.get(`${apiUrl}/api/files`, {params: {ids}}),
    getFilePreview: (fileId: string) => instance.get(`${apiUrl}/api/files/${fileId}/preview`, {responseType: 'blob'}),
    getFileContent: (fileId: string) => instance.get(`${apiUrl}/api/files/${fileId}/content`, {responseType: 'blob'}),
};

const clientWsChannel = {
    getAll: () => instance.get(`${apiUrl}/api/channels`),
};

export {
    clientWsWhatsappChannel,
    clientWsTelegramChannel,
    clientWsBillingPlan,
    clientWsAvitoChannel,
    clientWsUserProfile,
    clientWsUserInvitation,
    clientWsContentUpload,
    clientWsMultiUsers,
    clientWsMultiChatResource,
    clientWsMessageOperate,
    clientWsInvoiceResource,
    clientWsChat,
    clientWsMessage,
    clientWsContent,
    clientWsChannel
};
