import React, {useEffect, useState} from 'react';
import SignupForm from '../../molecula/SignupForm';

const Signup: React.FC = () => {
    const [referralCode, setReferralCode] = useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('referralcode');
        if (code) {
            setReferralCode(code);
        }
    }, []);

    return <SignupForm referralCode={referralCode || undefined}/>;
};

export default Signup;
