import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {ModalType} from "../../types/types";
import ModalWrapper from "../../atom/ModalWrapper";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";
import {clientWsUserProfile} from "../../../api/client-ws";
import {setTranscribeAudioMessages, setUseEnterToSend} from "../../../redux/slices/inbox-slice";

const InboxSettingsModal: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const typeOfModalToBeShown = useSelector((state: RootState) => state.common.typeOfModalToBeShown);
    const useEnterToSend = useSelector((state: RootState) => state.inbox.useEnterToSend) === 'true';
    const transcribeAudioMessages = useSelector((state: RootState) => state.inbox.transcribeAudioMessages) === 'true';

    const onRequestClose = () => {
        dispatch(setTypeOfModalToBeShown(null));
    };

    async function handleSubmit() {
        const settingsDto = [
            {
                key: "useEnterToSend",
                value: String(useEnterToSend), // Преобразование в строку для отправки на сервер
            },
            {
                key: "transcribeAudioMessages",
                value: String(transcribeAudioMessages),
            }
        ];
        try {
            for (const setting of settingsDto) {
                await clientWsUserProfile.updateSettingsProperty(setting);
            }
            dispatch(setTypeOfModalToBeShown(null));
        } catch (error) {
            console.error('Error updating user profile:', error);
        }
    }

    const toggleEnterToSend = () => {
        dispatch(setUseEnterToSend(String(!useEnterToSend))); // Переключаем значение и сохраняем его как строку
    };

    const toggleTranscribeAudioMessages = () => {
        dispatch(setTranscribeAudioMessages(String(!transcribeAudioMessages))); // Переключаем значение и сохраняем его как строку
    };

    return (
        <ModalWrapper
            isOpen={typeOfModalToBeShown === ModalType.InboxSettings}
            onRequestClose={onRequestClose}
            contentLabel="Настройки"
            okButtonText="Сохранить"
            cancelButtonText="Отмена"
            onOkButtonClick={handleSubmit}
            badgeRotate={true}
        >
            <div className="flex flex-col">
                <label className="flex pb-4 items-center justify-between hover:cursor-pointer space-x-3">
                    <span className={`${useEnterToSend ? 'text-slate-800' : 'text-slate-400'}`}>Включить отправку сообщений по Enter</span>
                    <div
                        className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
                        <input
                            type="checkbox"
                            name="toggleEnter"
                            id="toggleEnter"
                            className="toggle-checkbox hidden"
                            checked={useEnterToSend}
                            onChange={toggleEnterToSend}
                        />
                        <label htmlFor="toggleEnter"
                               className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${useEnterToSend ? 'bg-green-500' : 'bg-slate-300'}`}>
                            <span
                                className={`toggle-dot absolute left-1 my-1 w-4 h-4 bg-white rounded-full shadow transition-transform duration-300 ease-in-out transform ${useEnterToSend ? 'translate-x-4' : ''}`}></span>
                        </label>
                    </div>
                </label>


                <label className="flex items-center justify-between hover:cursor-pointer space-x-3">
                    <div className={`flex flex-col w-3/4`}>
                        <span
                            className={`${transcribeAudioMessages ? 'text-slate-800' : 'text-slate-400'}`}>Транскрибировать аудио сообщения.</span>
                        <p
                            className={`text-xs ${transcribeAudioMessages ? 'text-slate-800' : 'text-slate-400'}`}>Экспериментальная
                            функция доступна только в браузере Chrome. Для её работы необходимо, чтобы микрофон был
                            включен, так как распознавание звука осуществляется через него.</p>
                    </div>
                    <div
                        className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
                        <input
                            type="checkbox"
                            name="toggleTranscribe"
                            id="toggleTranscribe"
                            className="toggle-checkbox hidden"
                            checked={transcribeAudioMessages}
                            onChange={toggleTranscribeAudioMessages}
                        />
                        <label htmlFor="toggleTranscribe"
                               className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${transcribeAudioMessages ? 'bg-green-500' : 'bg-slate-300'}`}>
                            <span
                                className={`toggle-dot absolute left-1 my-1 w-4 h-4 bg-white rounded-full shadow transition-transform duration-300 ease-in-out transform ${transcribeAudioMessages ? 'translate-x-4' : ''}`}></span>
                        </label>
                    </div>
                </label>

            </div>
        </ModalWrapper>
    );
};

export default InboxSettingsModal;
