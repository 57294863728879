import React, {useCallback, useEffect, useRef, useState} from 'react';
import Chat from './Chat';
import {useSelector} from 'react-redux';
import {RootState} from '../../../redux';
import {useFetchChats} from "../../../hooks/useFetchChats";
import {Connection} from "../../types/types";
import {clientWsChannel} from "../../../api/client-ws";
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";

const Chats: React.FC = () => {
    const {page, setPage, totalPages, loading, fetchChats} = useFetchChats();
    const chats = useSelector((state: RootState) => state.inbox.chats || []);
    const observer = useRef<IntersectionObserver | null>(null);
    const [connections, setConnections] = useState<Connection[]>([]);
    const [connectionsLoading, setConnectionsLoading] = useState(true); // Новое состояние

    // Загрузка чатов и подключений при монтировании компонента
    useEffect(() => {
        fetchChats();
        fetchConnections();
        // eslint-disable-next-line
    }, []);

    const fetchConnections = async () => {
        setConnectionsLoading(true); // Начало загрузки
        try {
            const response = await clientWsChannel.getAll();
            setConnections(response.data);
        } catch (error) {
            console.error('Error fetching connections:', error);
        } finally {
            setConnectionsLoading(false); // Завершение загрузки
        }
    };

    const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && page < totalPages - 1) {
            setPage(page + 1);
        }
    }, [loading, page, totalPages, setPage]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver(handleObserver, options);
        const target = document.querySelector('#load-more-trigger');
        if (target) {
            observer.current.observe(target);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [handleObserver]);

    return (
        <div className="flex-1 overflow-y-auto overflow-hidden">
            {connectionsLoading ? ( // Показываем лоадер до завершения загрузки подключений
                <div className="text-center p-4 animate-pulse">Загрузка подключений...</div>
            ) : connections.length > 0 ? (
                chats.map(chat => (
                    <Chat key={chat.chatId} chat={chat}/>
                ))
            ) : (
                <div className="text-center p-4">
                    <p className="text-gray-500 mb-4">Нет подключений</p>
                    <Button
                        onClick={() => window.location.href = '/connections/add-connection'}
                        title={`Добавить подключение`}
                        buttonSize={ButtonSize.FULL}
                        buttonColor={ButtonColor.GREEN}
                        customClass={`shadow`}
                    />
                </div>
            )}
            {loading && <div className="text-center p-4 animate-pulse">Загрузка...</div>}
            {page < totalPages - 1 && (
                <span id="load-more-trigger" className="h-1"> </span>
            )}
        </div>
    );
};

export default Chats;
