import React from 'react';
import {FaEye, FaEyeSlash} from "react-icons/fa6";

export const enum InputType {
    EMAIL = 'email',
    TEXT = 'text',
    PASSWORD = 'password'
}

interface InputFieldProps {
    type: InputType;
    name: string;
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    showPassword?: boolean;
    togglePasswordVisibility?: () => void;
    disabled?: boolean;
    customClass?: string;
    error?: string;  // Добавлено для отображения ошибки
}

const InputField: React.FC<InputFieldProps> = ({
                                                   type,
                                                   name,
                                                   placeholder,
                                                   value,
                                                   onChange,
                                                   showPassword,
                                                   togglePasswordVisibility,
                                                   disabled,
                                                   customClass = '',
                                                   error  // Добавлено для отображения ошибки
                                               }) => {
    return (
        <div className="relative">
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`
                    ${customClass}
                    ${togglePasswordVisibility ? `pr-12` : ``}
                    w-full
                    py-2 px-3
                    border border-slate-300
                    bg-white
                    rounded-lg
                    hover:shadow-sm
                    focus:shadow-sm
                    transition-shadow
                    focus:outline-none
                    ${error ? 'border border-red-400' : ''}
                `}
            />
            {togglePasswordVisibility && (
                <span
                    className="absolute right-5 top-5 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <FaEyeSlash className="text-xl text-slate-600"/> :
                        <FaEye className="text-xl text-slate-600"/>}
                </span>
            )}
            {error && (
                <p className="absolute text-red-500 text-xs mt-1 leading-tight ml-2 max-w-full w-full break-words">
                    {error}
                </p>
            )}
        </div>
    );
};

export default InputField;
