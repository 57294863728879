// Dashboard.tsx

import React, {useEffect, useState} from 'react';
import {useHeader} from '../../context/HeaderContext';
import {clientWsUserProfile} from "../../api/client-ws";
import CurrentBillingPlanWidget from "../molecula/dashboard/CurrentBillingPlanWidget";
import ChannelsWidget from "../molecula/dashboard/ChannelsWidget";
import ContentWrapper from "../atom/ContentWrapper";

const Dashboard: React.FC = () => {
    const {setHeaderContent} = useHeader();
    const [fullName, setFullName] = useState<string>("");

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await clientWsUserProfile.load();
                setFullName(response.data.fullName);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
        fetchUserProfile();
    }, []);

    useEffect(() => {
        setHeaderContent('Добро пожаловать! ' + fullName);
    }, [setHeaderContent, fullName]);

    return (
        <div className="flex flex-wrap gap-4 p-1">
            <ContentWrapper fullHeight={true}>
                <CurrentBillingPlanWidget/>
            </ContentWrapper>
            <ContentWrapper fullHeight={true}>
                <ChannelsWidget/>
            </ContentWrapper>
        </div>
    );
};

export default Dashboard;
