import React, {useEffect, useRef, useState} from 'react';
import {clientWsChat, clientWsMultiChatResource} from "../../../api/client-ws";
import {ChannelType, ChatInterface, ChatSearchResponse, SingleAndMultiChatIdsRequest} from "../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {setChats, setReloadMessages, setSelectedChat} from "../../../redux/slices/inbox-slice";

interface FindSingleChatsParams {
    request: {
        chatTitle: string;
    };
}

interface ChatOption {
    chatTitle: string;
    channelType: string;
    chatId: string;
}

const ChatMergeOptions: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const selectedChat = useSelector((state: RootState) => state.inbox.selectedChat);
    const chats = useSelector((state: RootState) => state.inbox.chats || []);

    const [query, setQuery] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [selectedChats, setSelectedChats] = useState<ChatOption[]>([]);

    // Обновляем selectedChats при изменении selectedChat
    useEffect(() => {
        if (selectedChat) {
            if (selectedChat.channelType === ChannelType.MULTICHAT && selectedChat.multiChatSettings?.joinedChats) {
                // Если тип MULTICHAT, наполняем из multiChatSettings.joinedChats
                setSelectedChats(
                    selectedChat.multiChatSettings.joinedChats.map(joinedChat => ({
                        chatTitle: joinedChat.chatTitle ?? "", // Устанавливаем значение по умолчанию
                        channelType: joinedChat.channelType,
                        chatId: joinedChat.chatId,
                    }))
                );
            } else {
                // В противном случае наполняем как одиночный чат
                setSelectedChats([{
                    chatTitle: selectedChat.chatTitle ?? "",
                    channelType: selectedChat.channelType,
                    chatId: selectedChat.chatId,
                }]);
            }
        } else {
            // Если selectedChat отсутствует, очищаем selectedChats
            setSelectedChats([]);
        }
    }, [selectedChat]);


    const [availableChats, setAvailableChats] = useState<ChatOption[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const searchTimeout = useRef<number | null>(null);

    const fetchChats = async (chatTitle: string) => {
        try {
            const data: FindSingleChatsParams = {request: {chatTitle}};
            const response = await clientWsMultiChatResource.findSingleChats(data);

            const filteredChats = response.data
                .filter((chat: ChatSearchResponse) =>
                    chat.chatId !== selectedChat?.chatId && !selectedChats.some(selected => selected.chatId === chat.chatId)
                )
                .map((chat: ChatSearchResponse) => ({
                    chatTitle: chat.chatTitle,
                    channelType: chat.channelType,
                    chatId: chat.chatId
                }));
            setAvailableChats(filteredChats);
        } catch (error) {
            console.error('Ошибка при загрузке чатов:', error);
        }
    };

    const handleInputChange = (value: string) => {
        setQuery(value);
        setIsDropdownOpen(true);

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = window.setTimeout(() => {
            if (value) {
                fetchChats(value);
            } else {
                setAvailableChats([]);
            }
        }, 500);
    };

    const handleSelect = async (chat: ChatOption) => {
        if (!selectedChat?.chatId) {
            return // Если чат для присоединения отсутствует
        }

        if (chat.chatId === selectedChat?.chatId) {
            return; // Если выбранный чат совпадает с currentChat, ничего не делаем
        }

        setSelectedChats([...selectedChats, chat]);
        setQuery('');
        setIsDropdownOpen(false);

        const data: SingleAndMultiChatIdsRequest = {
            singleChatId: chat.chatId,
            multiChatId: selectedChat?.chatId
        };

        if (data.multiChatId) {
            try {
                const attachSingleChatToChatResponse = await clientWsMultiChatResource.attachSingleChatToChat(data);
                const findChatByIdResponse = await clientWsChat.findChatById(attachSingleChatToChatResponse.data.multiChatId);
                const newChat: ChatInterface = findChatByIdResponse.data;

                // Обновляем общий список чатов, заменяя старый selectedChat на новый
                const newChats = chats.map(chat =>
                    chat.chatId === selectedChat.chatId ? newChat : chat
                );
                dispatch(setChats(newChats));

                dispatch(setSelectedChat(newChat));
                dispatch(setReloadMessages(true));
            } catch (error) {
                console.error('Ошибка при подключении чата:', error);
            }
        } else {
            console.error('multiChatId не может быть null');
        }
    };

    const handleRemove = async (chatTitle: string) => {
        // Проверяем, что не пытаемся удалить единственный чат
        if (selectedChats.length === 1) {
            return; // Запрещаем удаление, если это единственный отображаемый чат
        }

        // Находим удаляемый чат
        const chatToRemove = selectedChats.find(selected => selected.chatTitle === chatTitle);
        if (!chatToRemove) return;

        // Подготавливаем данные для detachSingleChat
        if (!selectedChat) return;
        const data: SingleAndMultiChatIdsRequest = {
            singleChatId: chatToRemove.chatId,
            multiChatId: selectedChat.chatId, // ID текущего multiChat
        };

        try {
            // Вызываем detachSingleChat с подготовленными данными
            const detachSingleChatResponse = await clientWsMultiChatResource.detachSingleChat(data);

            // Обновляем список чатов после успешного удаления
            setSelectedChats(selectedChats.filter(selected => selected.chatTitle !== chatTitle));

            // Обновляем данные текущего multiChat
            const findChatByIdResponse = await clientWsChat.findChatById(detachSingleChatResponse.data.chatId);
            const newChat: ChatInterface = findChatByIdResponse.data;

            // Обновляем общий список чатов, заменяя старый selectedChat на новый
            const newChats = chats.map(chat =>
                chat.chatId === selectedChat.chatId ? newChat : chat
            );
            dispatch(setChats(newChats));

            // Диспатчим новый чат и флаг для обновления сообщений
            dispatch(setSelectedChat(newChat));
            dispatch(setReloadMessages(true));

        } catch (error) {
            console.error("Ошибка при попытке отсоединить чат или обновить данные:", error);
        }
    };


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            if (searchTimeout.current) clearTimeout(searchTimeout.current);
        };
    }, []);

    return (
        <div className="flex flex-col items-center p-6 w-full max-w-lg mx-auto h-full max-h-screen overflow-y-auto"><h2
            className="text-xl font-semibold text-slate-700 mb-4">Подключение чатов</h2>
            <p className="text-slate-600 mb-4">Выберите чат для объединения:</p>

            <div className="relative w-full" ref={dropdownRef}>
                <div className="flex flex-wrap gap-2 mb-2">
                    {selectedChats.map((chat) => (
                        <div key={chat.chatTitle}
                             className="flex items-center bg-blue-100 text-blue-700 rounded-lg px-3 py-1">
                            <span>{chat.chatTitle} ({chat.channelType})</span>
                            {selectedChats.length > 1 && (
                                <button
                                    onClick={() => handleRemove(chat.chatTitle)}
                                    className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                                >
                                    &times;
                                </button>
                            )}
                        </div>
                    ))}
                </div>

                <input
                    type="text"
                    value={query}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={() => setIsDropdownOpen(true)}
                    className="border border-slate-300 rounded px-4 py-2 w-full text-slate-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Поиск..."
                />
                {isDropdownOpen && availableChats.length > 0 && (
                    <ul className="absolute z-10 w-full bg-white border border-slate-300 rounded-md shadow-lg mt-1 max-h-48 overflow-y-auto">
                        {availableChats.map((chat, index) => (
                            <li
                                key={index}
                                onClick={() => handleSelect(chat)}
                                className="cursor-pointer select-none px-4 py-2 hover:bg-blue-500 hover:text-white"
                            >
                                <span className="font-semibold">{chat.chatTitle}</span>
                                <span className="text-slate-500 ml-2">({chat.channelType})</span>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ChatMergeOptions;
