import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from 'react';
import {Navigate, NavLink} from 'react-router-dom';
import {handleLogError} from '../../../api/base-api';
import AuthContext from '../../../context/AuthContext';
import {authWsSignin} from "../../../api/auth-ws";
import {Helmet} from "react-helmet";
import FormWrapper from "../../atom/FormWrapper";
import InputField, {InputType} from "../../atom/InputField";
import CheckboxField from "../../atom/ChekboxField";
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";
import ErrorMessage from "../../atom/ErrorMessage";
import {useProfileHelper} from "../../../hooks/useProfileHelper";

const Login: React.FC = () => {
    const authContext = useContext(AuthContext);
    const {loadProfileData} = useProfileHelper();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(authContext?.userIsAuthenticated() ?? false);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setIsLoggedIn(authContext?.userIsAuthenticated() ?? false);
    }, [authContext]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value, checked} = e.target;
        if (name === "email") setEmail(value);
        if (name === "password") setPassword(value);
        if (name === "rememberMe") setRememberMe(checked);

        setErrors(prev => ({...prev, [name]: ''})); // Убираем ошибку при изменении
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};
        if (!email) newErrors.email = 'Email обязателен';
        if (!password) newErrors.password = 'Пароль обязателен';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!validateForm()) return;

        try {
            const response = await authWsSignin.signIn({email, password, rememberMe});
            const {token} = response.data;
            authContext?.userLogin(token, rememberMe); // Передаем rememberMe

            setIsLoadingProfile(true); // Начинаем загрузку профиля
            await loadProfileData(); // Загружаем данные профиля
            setIsLoggedIn(true); // Теперь можно считать пользователя авторизованным
        } catch (error: any) {
            handleLogError(error);
            const serverErrors: { [key: string]: string } = {};
            if (error.response?.data?.message === 'Invalid email or password') {
                serverErrors.form = 'Неправильный email или пароль';
            } else if (error.response?.data?.code === 'WRONG_CREDENTIALS') {
                serverErrors.form = 'Неправильный email или пароль';
            } else {
                serverErrors.form = 'Ошибка при авторизации';
            }
            setErrors(prev => ({...prev, ...serverErrors}));
        } finally {
            setIsLoadingProfile(false); // Окончание загрузки профиля
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    if (isLoggedIn && !isLoadingProfile) {
        return <Navigate to="/"/>;
    }

    return (
        <div className="flex justify-center items-center h-screen">
            <Helmet>
                <title>Авторизация | ChatHolder</title>
            </Helmet>
            <div className="flex flex-col items-center">
                <div className="text-center mb-8 w-full max-w-md">
                    <h1 className="sm:text-7xl text-6xl font-bold text-dark mb-4">Chatholder</h1>
                    <p className="sm:text-2xl text-xl text-slate-600">
                        Инновационная платформа для связи через мессенджеры и соц сети
                    </p>
                </div>

                <div className="w-full max-w-md">
                    <FormWrapper onSubmit={handleSubmit}>
                        <>
                            <InputField
                                type={InputType.EMAIL}
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={handleInputChange}
                                error={errors.email}
                            />
                            <InputField
                                type={showPassword ? InputType.TEXT : InputType.PASSWORD}
                                name="password"
                                placeholder="Пароль"
                                value={password}
                                onChange={handleInputChange}
                                showPassword={showPassword}
                                togglePasswordVisibility={togglePasswordVisibility}
                                error={errors.password}
                            />
                            <CheckboxField
                                name="rememberMe"
                                checked={rememberMe}
                                onChange={handleInputChange}
                                label="Не выходить при закрытии браузера"
                            />
                            <div className="relative">
                                <Button
                                    title="Авторизоваться"
                                    buttonSize={ButtonSize.FULL}
                                    type="submit"
                                    buttonColor={ButtonColor.GREEN}
                                    customClass="py-6"
                                />
                                {errors.form && <ErrorMessage message={errors.form}/>}
                            </div>
                        </>
                    </FormWrapper>
                </div>

                <div className="mt-5 text-center">
                    <span className="text-slate-600">
                        У вас нет учетной записи?&nbsp;
                        <NavLink to='/signup' className="text-success-green hover:underline">Регистрация</NavLink>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Login;
