import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {cleanAttachments, pushMessage, setMessageText} from "../../../redux/slices/inbox-slice";
import UploadFileButton from "../../atom/UploadFileButton";
import {ChannelType, DirectionType} from "../../types/types";
import {clientWsAvitoChannel, clientWsTelegramChannel, clientWsWhatsappChannel} from "../../../api/client-ws";
import {FaCircleArrowUp} from "react-icons/fa6";
import SocialNetworkBadge from "../../atom/SocialNetworkBadge";
import ThemedButton from "../../atom/ThemedButton";
import Tooltip from "../../atom/Tooltip";
import Button, {ButtonColor} from "../../atom/Button";

interface MessageSendOption {
    channelType: string;
    chatId: string;
    channelId: string;
}

const TextAreaWithButtons: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const messageText = useSelector((state: RootState) => state.inbox.messageText) ?? '';
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const messages = useSelector((state: RootState) => state.inbox.messages);
    const selectedChat = useSelector((state: RootState) => state.inbox.selectedChat);
    const attachments = useSelector((state: RootState) => state.inbox.attachments);
    const useEnterToSend = useSelector((state: RootState) => state.inbox.useEnterToSend) ?? false;

    const dropdownRef = useRef<HTMLDivElement>(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [targetChat, setTargetChat] = useState(selectedChat);

    const getLastMessageSendOption = useCallback((): MessageSendOption | null => {
        if (messages.length === 0) {
            return null;
        }

        const lastMessage = messages[messages.length - 1];
        return {
            channelType: lastMessage.channelType || '',
            chatId: lastMessage.chatId || '',
            channelId: lastMessage.channelId || '',
        };
    }, [messages]);

    const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false); // Закрываем меню, если клик вне элемента
        }
    };

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside); // Добавляем слушатель
        } else {
            document.removeEventListener('mousedown', handleClickOutside); // Удаляем слушатель
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Чистим слушатель при размонтировании
        };
    }, [isDropdownOpen]);


    const handleToggleDropdown = () => {
        if ((selectedChat?.multiChatSettings?.joinedChats?.length ?? 0) >= 1) {
            setIsDropdownOpen(!isDropdownOpen);
        }
    };


    const handleSelectChat = (chat: any) => {
        setTargetChat(chat);  // Обновление целевого чата для отправки
        setIsDropdownOpen(false);  // Закрытие dropdown
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const target = e.target;
        dispatch(setMessageText(target.value));

        // Автоматическое изменение высоты textarea
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleSendMessage = async () => {
        if (!messageText.trim() && attachments.length === 0) return;

        // Получаем `channelType` и `chatId` последнего сообщения
        const lastMessageSendOption = getLastMessageSendOption();
        const channelTypeToUse = targetChat?.channelType || lastMessageSendOption?.channelType;
        const chatIdToUse = targetChat?.chatId || lastMessageSendOption?.chatId;
        const channelIdToUse = targetChat?.channelId || lastMessageSendOption?.channelId || '';

        if (!chatIdToUse || !channelTypeToUse) {
            console.error("Chat ID или Channel Type отсутствуют для отправки сообщения.");
            return;
        }

        const sendMessage = async (text: string, attachmentId: string | null = null) => {
            const messageDto = {
                messageId: '',
                chatId: chatIdToUse,
                body: text,
                channelId: channelIdToUse,
                channelType: channelTypeToUse,
                directionType: DirectionType.OUTGOING,
                status: 'SENT',
                externalContactId: '',
                messageTimestamp: new Date().toISOString(),
                messageType: 'TEXT',
                sentByUserId: null,
                read: true,
                mediaFileId: attachmentId,
            };

            try {
                let response;
                if (channelTypeToUse === ChannelType.WHATSAPP) {
                    response = await clientWsWhatsappChannel.sendMessage(channelIdToUse, messageDto);
                } else if (channelTypeToUse === ChannelType.TELEGRAM) {
                    response = await clientWsTelegramChannel.sendMessage(channelIdToUse, messageDto);
                } else if (channelTypeToUse === ChannelType.AVITO) {
                    response = await clientWsAvitoChannel.sendMessage(channelIdToUse, messageDto);
                }

                if (response) {
                    dispatch(pushMessage(messageDto));
                    dispatch(setMessageText(''));
                    dispatch(cleanAttachments());

                    // Сброс высоты textarea после отправки сообщения
                    if (textareaRef.current) {
                        textareaRef.current.style.height = 'auto';
                    }
                } else {
                    console.error(`Response is undefined for ${channelTypeToUse}`);
                }
            } catch (error) {
                console.error(`Error sending to ${channelTypeToUse}:`, error);
            }
        };

        if (channelTypeToUse === ChannelType.TELEGRAM) {
            await sendMessage(messageText, attachments[0]?.fileId ?? null);
        } else {
            if (attachments.length > 0) {
                await sendMessage('', attachments[0]?.fileId);
            }
            if (messageText.trim()) {
                await sendMessage(messageText);
            }
        }
    };


    // Обработчик нажатий клавиш в textarea
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (useEnterToSend) {
            if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                e.preventDefault(); // Отправка сообщения при нажатии Enter
                handleSendMessage();
            }
        } else {
            if (e.key === 'Enter' && e.ctrlKey) {
                e.preventDefault(); // Отправка сообщения при нажатии Ctrl + Enter
                handleSendMessage();
            }
        }
    };

    return (
        <div
            className="w-full flex flex-col gap-1 p-2 pt-1 shadow space-y-2 rounded-lg hover:shadow-md2 focus-within:shadow-md2 transition-shadow">
            <textarea
                ref={textareaRef}
                value={messageText}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Введите сообщение..."
                className="flex w-full max-h-[200px] px-1 rounded-t-lg resize-none focus:outline-none placeholder:text-slate-400"
                rows={1}
            />
            <div className="flex justify-between items-center bg-white rounded-b-lg">
                <UploadFileButton/>
                <div className="flex items-center relative">
                    <div ref={dropdownRef}>

                        {getLastMessageSendOption() && (
                            <Tooltip text={`Выбрать канал для отправки`} arrow={true}>
                                <Button
                                    buttonColor={ButtonColor.TRANSPARENT} customClass={`rounded-r-none`}
                                    onClick={handleToggleDropdown} hover={false}>
                                    <SocialNetworkBadge
                                        // Если выбран targetChat, используем его тип; иначе, getLastMessageChannelType
                                        network={targetChat?.channelType ?? getLastMessageSendOption()?.channelType ?? ''}
                                        short={false}
                                        inverted={true}
                                        background={false}
                                        border={true}
                                        hover={true}
                                        customClass={`rounded-r-none`}
                                    />
                                </Button>
                            </Tooltip>
                        )}

                        {isDropdownOpen && (
                            <div
                                className="absolute bottom-full mb-2 w-48 bg-white shadow-md2 rounded-lg p-2 -left-6 ml-0.5 flex flex-col">
                                {selectedChat?.multiChatSettings?.joinedChats.map((chat) => (
                                    <div
                                        key={chat.chatId}
                                        onClick={() => handleSelectChat(chat)}
                                        className="flex justify-center items-center space-x-2 cursor-pointer px-2 py-1 hover:bg-slate-100 rounded-md text-sm"
                                    >
                                        <Tooltip text={chat.channelType || ''}>
                                            <SocialNetworkBadge
                                                network={chat.channelType || ''}
                                                inverted={true}
                                                background={false}
                                                onlyLogo={true}
                                                customClass={`w-3 h-3 flex justify-center items-center`}
                                            />
                                        </Tooltip>
                                        <Tooltip text={chat.chatTitle || ''}>
                                            {chat.chatTitle ? (chat.chatTitle.length > 14 ? `${chat.chatTitle.slice(0, 14)}...` : chat.chatTitle) : ''}
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <Tooltip text={`Отправить`} arrow={true}>
                        <Button buttonColor={ButtonColor.TRANSPARENT}>
                            <ThemedButton
                                inverted={true}
                                backgroundInverted={true}
                                theme={targetChat?.channelType ?? getLastMessageSendOption()?.channelType ?? ''}
                                onClick={handleSendMessage}
                                customClass={`rounded-l-none`}
                            >
                                <FaCircleArrowUp/>
                            </ThemedButton>
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default TextAreaWithButtons;
