import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FaChevronDown, FaChevronUp, FaCog, FaInbox, FaPlug} from 'react-icons/fa';
import SidebarLink from '../atom/SidebarLink';
import {FaBarsProgress, FaCode, FaIdCard, FaPeopleGroup, FaWallet} from "react-icons/fa6";
import {setSidebarButtonVisible, setSidebarVisible} from "../../redux/slices/sidebar-slice";
import {RootState} from "../../redux";
import RoleBasedRender from "../../utils/RoleBasedRender";
import {RoleType} from "../types/types";

const Sidebar: React.FC = () => {
    const dispatch = useDispatch();
    const sidebarVisible = useSelector((state: RootState) => state.sidebar.sidebarVisible); // Redux sidebar

    const [settingsOpen, setSettingsOpen] = useState(false);

    const checkSidebarFit = () => {
        const screenWidth = window.innerWidth;
        // Условие, при котором sidebar считается не вмещающимся на экран
        if (screenWidth < 1024) {
            dispatch(setSidebarButtonVisible(true));
        } else {
            dispatch(setSidebarButtonVisible(false));
        }
    };

    useEffect(() => {
        checkSidebarFit();
        window.addEventListener('resize', checkSidebarFit);
        return () => {
            window.removeEventListener('resize', checkSidebarFit);
        };
        // eslint-disable-next-line
    }, []);

    const toggleSettings = () => {
        setSettingsOpen(!settingsOpen);
    };

    const handleMenuClick = () => {
        if (sidebarVisible) {
            dispatch(setSidebarVisible(false));
        }
    };

    const handleBackdropClick = () => {
        if (sidebarVisible) {
            dispatch(setSidebarVisible(false));
        }
    };

    return (
        <>
            {sidebarVisible && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
                    onClick={handleBackdropClick}
                />
            )}

            <div
                className={`fixed top-0 left-0 w-64 h-full bg-gradient-to-b from-[#f8fcfe] to-[#FFFFFF] z-50 transform ${
                    sidebarVisible ? 'translate-x-0' : '-translate-x-full'
                } lg:transform-none lg:relative lg:translate-x-0 transition-transform duration-300 ease-in-out`}
            >
                <div className={`w-64 flex flex-col h-screen p-3 ${!sidebarVisible ? 'pr-0' : ''}`}>
                    <nav className="flex-1">
                        <SidebarLink
                            to="/"
                            title="Главная"
                            iconClass="w-10 h-10 bg-center bg-contain bg-no-repeat bg-[url('./icons/logo.png')]"
                            onClick={handleMenuClick}
                        />
                        <SidebarLink
                            to="/inbox"
                            Icon={FaInbox}
                            title="Сообщения"
                            onClick={handleMenuClick}
                        />
                        <RoleBasedRender allowedRoles={[RoleType.OWNER]}>
                            {/*<SidebarLink*/}
                            {/*    to="/contacts"*/}
                            {/*    Icon={FaAddressBook}*/}
                            {/*    title="Контакты"*/}
                            {/*    onClick={handleMenuClick}*/}
                            {/*/>*/}
                            <SidebarLink
                                to="/contacts-widget"
                                Icon={FaCode}
                                title="Виджет на сайт"
                                onClick={handleMenuClick}
                            />
                            <SidebarLink
                                to="/connections"
                                Icon={FaPlug}
                                title="Подключения"
                                onClick={handleMenuClick}
                            />
                        </RoleBasedRender>
                        <div className="mt-auto">
                            <div
                                className="flex items-center px-4 py-3 text-slate-500 text-lg cursor-pointer rounded-md hover:bg-slate-100 hover:text-slate-700"
                                onClick={toggleSettings}
                            >
                                <FaCog className="mr-2 text-xl"/>
                                <span>Настройки</span>
                                {settingsOpen ? (
                                    <FaChevronUp className="ml-auto text-xl"/>
                                ) : (
                                    <FaChevronDown className="ml-auto text-xl"/>
                                )}
                            </div>
                            {settingsOpen && (
                                <div className="pl-10 mt-2">
                                    <RoleBasedRender allowedRoles={[RoleType.OWNER]}>
                                        <SidebarLink
                                            to="/settings/billing"
                                            Icon={FaWallet}
                                            title="Платежи"
                                            onClick={handleMenuClick}
                                        />
                                    </RoleBasedRender>
                                    <RoleBasedRender allowedRoles={[RoleType.OWNER]}>
                                        <SidebarLink
                                            to="/settings/team"
                                            Icon={FaPeopleGroup}
                                            title="Команда"
                                            onClick={handleMenuClick}
                                        />
                                    </RoleBasedRender>
                                    <SidebarLink
                                        to="/settings/profile"
                                        Icon={FaIdCard}
                                        title="Профиль"
                                        onClick={handleMenuClick}
                                    />
                                </div>
                            )}
                        </div>
                    </nav>
                    <div className="mt-4">
                        <SidebarLink
                            to="https://feather-astrodon-57b.notion.site/Chatholder-6e32d7f085bf4ca8a4608957905b8cf7?pvs=74"
                            target="_blank"
                            rel="noopener noreferrer"
                            Icon={FaBarsProgress}
                            title="Change log"
                            onClick={handleMenuClick}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
