import React from 'react';

const ChatEditorHeader: React.FC = () => {
    return (
        <div className="flex items-center justify-between h-16 border-b-[#ddd] border-b border-solid">
            {/*<h2>Редактирование чата</h2>*/}
        </div>
    );
};

export default ChatEditorHeader;
