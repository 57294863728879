import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../../redux';
import {clientWsMessage} from '../../../api/client-ws';
import {cleanMessagesWithoutId, pushMessages, setMessages} from '../../../redux/slices/inbox-slice';
import {useFetchChats} from "../../../hooks/useFetchChats";
import {ChannelType} from "../../types/types";

const PollingComponent: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const selectedChat = useSelector((state: RootState) => state.inbox.selectedChat);
    const showUnread = useSelector((state: RootState) => state.inbox.showUnread);
    const filteredChatId = useSelector((state: RootState) => state.inbox.filteredChannelId);
    const {fetchChats} = useFetchChats();

    const fetchMessages = async () => {
        if (selectedChat?.chatId) {
            try {
                let response;

                if (selectedChat.channelType !== ChannelType.MULTICHAT) {
                    response = await clientWsMessage.findInChat(selectedChat.chatId, 0, 10);
                } else {
                    // Теперь joinedChats содержит объекты ChatInterface, из которых извлекаем chatId
                    const chatIds = selectedChat.multiChatSettings?.joinedChats.map(joinedChat => joinedChat.chatId) || [];

                    // Проверка, что массив chatIds не пуст
                    if (chatIds.length > 0) {
                        response = await clientWsMessage.findInChats(chatIds, 0, 10);
                    } else {
                        console.warn("Нет доступных chatIds в multiChatSettings.joinedChats для MULTICHAT");
                        return;
                    }
                }

                if (response && response.data && Array.isArray(response.data.content)) {
                    dispatch(pushMessages(response.data.content));
                    dispatch(cleanMessagesWithoutId());
                } else {
                    dispatch(setMessages([]));
                }
            } catch (error) {
                console.error('Error loading messages:', error);
            }
        }
    };

    useEffect(() => {
        const startPolling = () => {
            return setInterval(async () => {
                try {
                    await Promise.all([fetchMessages(), await fetchChats()]);
                } catch (error) {
                    console.error('Error during polling:', error);
                }
            }, 10000);
        };

        const pollingInterval = startPolling();

        return () => clearInterval(pollingInterval); // Очищаем интервал при размонтировании компонента
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChat?.chatId, showUnread, filteredChatId]); // Запуск пулинга при монтировании и изменении chatId

    return null; // Этот компонент только выполняет пулинг
};

export default PollingComponent;
