import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {parseJwt} from '../api/base-api';
import {useProfileHelper} from "../hooks/useProfileHelper";

interface AuthContextType {
    token: string | null;
    getUser: () => any;
    userIsAuthenticated: () => boolean;
    userLogin: (token: string, rememberMe?: boolean) => void;
    userLogout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const {removeProfileData} = useProfileHelper();
    const {loadProfileData} = useProfileHelper();

    const [cookies, setCookie, removeCookie] = useCookies(['chatholder-auth-token']);
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const tokenFromCookies = cookies['chatholder-auth-token'];
        if (tokenFromCookies) {
            setToken(tokenFromCookies);
        }
    }, [cookies]);

    const getUser = () => {
        const tokenFromCookies = cookies['chatholder-auth-token'];
        return tokenFromCookies ? parseJwt(tokenFromCookies) : null;
    };

    const userIsAuthenticated = () => {
        const tokenFromCookies = cookies['chatholder-auth-token'];

        if (!tokenFromCookies) {
            userLogout();
            return false;
        }

        const parsedToken = parseJwt(tokenFromCookies);
        if (!parsedToken || !parsedToken.exp) {
            userLogout();
            return false;
        }

        if (Date.now() > parsedToken.exp * 1000) {
            userLogout();
            return false;
        }

        return true;
    };

    const userLogin = async (token: string, rememberMe: boolean = false) => {
        const parsedToken = parseJwt(token);

        if (parsedToken && parsedToken.exp) {
            const cookieOptions: { path: string; sameSite: "lax"; expires?: Date } = {
                path: '/',
                sameSite: "lax",
            };

            if (rememberMe) {
                cookieOptions.expires = new Date(parsedToken.exp * 1000);
            }

            setCookie('chatholder-auth-token', token, cookieOptions);
            setToken(token);

            try {
                await loadProfileData(); // Загрузка профиля
            } catch (error) {
                console.error('Ошибка при загрузке профиля:', error);
                userLogout(); // Выполняем logout в случае ошибки
            }
        } else {
            console.error('Invalid token: unable to parse expiration date.');
            userLogout();  // Выполняем logout при некорректном токене
        }
    };


    const userLogout = () => {
        removeCookie('chatholder-auth-token', {path: '/'});
        removeProfileData();
        setToken(null);
    };

    return (
        <AuthContext.Provider value={{token, getUser, userIsAuthenticated, userLogin, userLogout}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;

export function useAuth(): AuthContextType {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

export {AuthProvider};
