import React from 'react';
import {FaEnvelopeCircleCheck} from "react-icons/fa6";
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";
import {clientWsChat} from "../../../api/client-ws";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import Tooltip from "../../atom/Tooltip";

const MarkChatAsReadButton: React.FC = () => {
    const selectedChat = useSelector((state: RootState) => state.inbox.selectedChat);
    const handleClick = async () => {
        if (selectedChat?.chatId !== null) {
            try {
                await clientWsChat.markAsRead(selectedChat?.chatId || '');
            } catch (e) {
                console.error(e);
            }
        }
    }
    return (
        <Tooltip text={"Пометить все сообщения как прочитанные"} arrow={true}>
            <Button icon={<FaEnvelopeCircleCheck/>}
                    onClick={handleClick}
                    buttonColor={ButtonColor.TRANSPARENT}
                    buttonSize={ButtonSize.NORMAL}/>
        </Tooltip>
    );
};

export default MarkChatAsReadButton;