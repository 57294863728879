import React, {useEffect, useState} from 'react';
import {useHeader} from '../../context/HeaderContext';
import ContentWrapper from "../atom/ContentWrapper";
import Button, {ButtonColor, ButtonSize} from "../atom/Button";
import InputField, {InputType} from "../atom/InputField";

interface ContactInfo {
    type: 'whatsapp' | 'telegram' | 'email' | 'phone';
    value: string;
}

const ContactsWidget: React.FC = () => {
    const {setHeaderContent} = useHeader();
    const [contacts, setContacts] = useState<ContactInfo[]>([]);
    const [widgetCode, setWidgetCode] = useState<string>('');
    const [copySuccess, setCopySuccess] = useState<string | null>(null);

    useEffect(() => {
        setHeaderContent('Генерация виджета контактов');
    }, [setHeaderContent]);

    // Добавить новый контакт
    const handleAddContact = (type: ContactInfo['type']) => {
        setContacts([...contacts, {type, value: ''}]);
    };

    // Изменение контакта
    const handleContactChange = (index: number, value: string) => {
        const updatedContacts = [...contacts];
        updatedContacts[index].value = value;
        setContacts(updatedContacts);
    };

    // Удаление контакта
    const handleDeleteContact = (index: number) => {
        setContacts(contacts.filter((_, i) => i !== index));
    };

    // Генерация HTML-кода виджета без пустых полей
    useEffect(() => {
        const dataAttributes = contacts
            .map((contact, index) => contact.value && `data-${contact.type}${index > 0 ? `-${index}` : ''}="${contact.value}"`)
            .filter(Boolean)
            .join(' ');

        const generatedScript = `
            <!-- Chatholder widget -->
            <script id="chatholder-widget"
                    src="https://prod.chatholder.ru/widgets/load-feedback-widget.js"
                    ${dataAttributes}>
            </script>
            <!-- Chatholder widget -->
        `;
        setWidgetCode(generatedScript);
    }, [contacts]);

    // Копирование кода в буфер обмена
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(widgetCode);
            setCopySuccess("Код скопирован!");
            setTimeout(() => setCopySuccess(null), 2000); // Сообщение исчезает через 2 секунды
        } catch (err) {
            setCopySuccess("Не удалось скопировать код");
        }
    };

    return (
        <ContentWrapper fullHeight={false}>
            <div className="p-6 space-y-6">
                <div className="space-y-4">
                    {contacts.map((contact, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <select
                                value={contact.type}
                                onChange={(e) =>
                                    setContacts(
                                        contacts.map((c, i) =>
                                            i === index ? {...c, type: e.target.value as ContactInfo['type']} : c
                                        )
                                    )
                                }
                                className="border rounded-lg p-2"
                            >
                                <option value="whatsapp">WhatsApp</option>
                                <option value="telegram">Telegram</option>
                                <option value="email">Email</option>
                                <option value="phone">Phone</option>
                            </select>
                            <InputField
                                type={InputType.TEXT}
                                name={""}
                                placeholder={`Введите ${contact.type}`}
                                value={contact.value}
                                onChange={(e) => handleContactChange(index, e.target.value)}
                            />
                            <Button onClick={() => handleDeleteContact(index)}
                                    buttonColor={ButtonColor.RED}
                                    buttonSize={ButtonSize.FULL}
                                    title={`Удалить`}
                            />
                        </div>
                    ))}
                    <div className="flex space-x-2">
                        <Button onClick={() => handleAddContact('whatsapp')}
                                buttonColor={ButtonColor.GREEN}
                                buttonSize={ButtonSize.FULL}
                                shadow={true}
                        >+ WhatsApp
                        </Button>
                        <Button onClick={() => handleAddContact('telegram')}
                                buttonColor={ButtonColor.GREEN}
                                buttonSize={ButtonSize.FULL}
                                shadow={true}
                        >+ Telegram
                        </Button>
                        <Button onClick={() => handleAddContact('email')}
                                buttonColor={ButtonColor.GREEN}
                                buttonSize={ButtonSize.FULL}
                                shadow={true}
                        >+ Email
                        </Button>
                        <Button onClick={() => handleAddContact('phone')}
                                buttonColor={ButtonColor.GREEN}
                                buttonSize={ButtonSize.FULL}
                                shadow={true}
                        >+ Phone
                        </Button>
                    </div>
                </div>

                <div className="mt-4 p-4 bg-slate-50 rounded-lg border border-gray-200">
                    <p className="text-sm text-gray-600 mb-2">
                        Скопируйте этот код и вставьте его перед закрывающимся тегом <code>&lt;/body&gt;</code> на
                        странице вашего сайта:
                    </p>
                    <code className="block whitespace-pre-wrap mb-4">{widgetCode}</code>
                    <div className={`w-48`}>
                        {copySuccess ? (
                            <Button buttonColor={ButtonColor.TRANSPARENT}
                                    buttonSize={ButtonSize.FULL}
                                    onClick={copyToClipboard}
                                    hover={false}
                                    title={`${copySuccess}`}
                            />
                        ) : (
                            <Button buttonColor={ButtonColor.GREEN_2}
                                    buttonSize={ButtonSize.FULL}
                                    onClick={copyToClipboard}
                                    title={"Скопировать код"}
                                    customClass={`shadow`}
                            />
                        )}
                    </div>
                </div>
            </div>
        </ContentWrapper>
    )
        ;
};

export default ContactsWidget;
