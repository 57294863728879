import React from "react";

interface NetworkStyles {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    text: string;
    background: string;
    backgroundInverted: string;
    border: string;
    logo: React.ReactNode;
    hover: string;
    hoverInverted: string;
    hoverLight: string;
}

const networkStyles: { [key: string]: NetworkStyles } = {
    whatsapp: {
        text: 'text-whatsapp',
        hover: 'hover:bg-whatsapp-20 hover:border-whatsapp-20',
        hoverInverted: 'hover:bg-whatsapp-20 hover:border-whatsapp-20',
        hoverLight: 'hover:bg-whatsapp-10 hover:border-whatsapp-10',
        background: 'bg-whatsapp',
        backgroundInverted: 'bg-whatsapp-10',
        border: 'border border-whatsapp-10',
        logo: <span className="fab fa-whatsapp"></span>,
    },
    telegram: {
        text: 'text-telegram',
        hover: 'hover:bg-telegram-20 hover:border-telegram-20',
        hoverInverted: 'hover:bg-telegram-20 hover:border-telegram-20',
        hoverLight: 'hover:bg-telegram-10 hover:border-telegram-10',
        background: 'bg-telegram',
        backgroundInverted: 'bg-telegram-10',
        border: 'border border-telegram-10',
        logo: <span className="fab fa-telegram-plane"></span>,
    },
    avito: {
        text: 'text-avito',
        hover: 'hover:bg-avito-20 hover:border-avito-20',
        hoverInverted: 'hover:bg-avito-20 hover:border-avito-20',
        hoverLight: 'hover:bg-avito-10 hover:border-avito-10',
        background: 'bg-avito',
        backgroundInverted: 'bg-avito-10',
        border: 'border border-avito-10',
        logo: <img src={"/assets/icons/avito.svg"} alt="avito"
                   className={`inline-block w-6 h-6`}/>,
    },
    multichat: {
        text: 'text-multichat',
        hover: 'hover:bg-multichat-20 hover:border-multichat-20',
        hoverInverted: 'hover:bg-multichat-20 hover:border-multichat-20',
        hoverLight: 'hover:bg-multichat-10 hover:border-multichat-10',
        background: 'bg-multichat',
        backgroundInverted: 'bg-multichat-10',
        border: 'border border-multichat-10',
        logo: <span className="fas fa-comments"></span>,
    },
    facebook: {
        text: 'text-facebook',
        hover: 'hover:bg-facebook-20 hover:border-facebook-20',
        hoverInverted: 'hover:bg-facebook-20 hover:border-facebook-20',
        hoverLight: 'hover:bg-facebook-10 hover:border-facebook-10',
        background: 'bg-facebook',
        backgroundInverted: 'bg-facebook-10',
        border: 'border border-facebook-10',
        logo: <span className="fab fa-facebook-f"></span>,
    },
    instagram: {
        text: 'text-instagram',
        hover: 'hover:bg-instagram-20 hover:border-instagram-20',
        hoverInverted: 'hover:bg-instagram-20 hover:border-instagram-20',
        hoverLight: 'hover:bg-instagram-10 hover:border-instagram-10',
        background: 'bg-instagram',
        backgroundInverted: 'bg-instagram-10',
        border: 'border border-instagram-10',
        logo: <span className="fab fa-instagram"></span>,
    },
    twitter: {
        text: 'text-twitter',
        hover: 'hover:bg-twitter-20 hover:border-twitter-20',
        hoverInverted: 'hover:bg-twitter-20 hover:border-twitter-20',
        hoverLight: 'hover:bg-twitter-10 hover:border-twitter-10',
        background: 'bg-twitter',
        backgroundInverted: 'bg-twitter-10',
        border: 'border border-twitter-10',
        logo: <span className="fab fa-twitter"></span>,
    },
};

export const getNetworkStyles = (
    network: string,
    inverted: boolean,
    background?: boolean,
    border?: boolean,
    hover?: boolean
) => {
    const networkLowerCase = network.toLowerCase();

    const styles = networkStyles[networkLowerCase] || {
        text: 'text-slate-400',
        background: 'bg-slate-400',
        backgroundInverted: 'bg-slate-100',
        border: 'border-slate-100',
        hover: 'hover:bg-slate-300',
        hoverInverted: 'hover:bg-slate-200',
        hoverLight: 'hover:bg-slate-50',
        logo: network.charAt(0).toUpperCase(),
    };

    const {
        text,
        hover: hoverClass,
        hoverInverted,
        hoverLight,
        background: bg,
        backgroundInverted,
        border: borderClass,
        logo,
    } = styles;

    let badgeClass = `${text}`;

    if (inverted) {
        if (background) {
            badgeClass += ` ${backgroundInverted}`;
        } else if (hover) {
            badgeClass += ` ${hoverLight}`;
        }
        if (border !== false) {
            badgeClass += ` ${borderClass}`;
        }
        if (hover !== false) {
            badgeClass += ` ${hoverInverted}`;
        }
    } else {
        if (background) {
            badgeClass += ` ${bg} text-white`;
        }
        if (border !== false) {
            badgeClass += ` ${borderClass}`;
        }
        if (hover !== false) {
            badgeClass += ` ${hoverClass}`;
        }
    }

    return {
        badgeClass,
        iconClass: logo,
    };
};
