import React, {useEffect, useRef, useState} from 'react';
import {format} from 'date-fns';
import {AttachmentResponse, ChannelType, DirectionType, MessageInterface} from "../../types/types";
import {updateMessage} from "../../../redux/slices/inbox-slice";
import {AppDispatch} from "../../../redux";
import {useDispatch} from "react-redux";
import {clientWsContent, clientWsMessageOperate} from "../../../api/client-ws";
import FileInfo from "./FileInfo";
import {formatMessageBody} from "../../../utils/FormatMessageBody";
import SocialNetworkBadge from "../../atom/SocialNetworkBadge";
import Tooltip from "../../atom/Tooltip";

interface ChatMessageProps {
    message: MessageInterface;
    channelType?: ChannelType;
}

const Message: React.FC<ChatMessageProps> = ({message, channelType}) => {
    const messageRef = useRef<HTMLDivElement | null>(null);
    const dispatch: AppDispatch = useDispatch();

    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileBlob, setFileBlob] = useState<Blob | null>(null);
    const [fileInfo, setFileInfo] = useState<AttachmentResponse | null>(null);

    const isOutgoing = message.directionType === DirectionType.OUTGOING;
    const isIncoming = message.directionType === DirectionType.INCOMING;
    const isUnread = !message.read;

    // const channelTypeClass = `text-xs text-slate-500 ${isOutgoing ? 'text-right' : 'text-left'}`;
    const formattedTimestamp = format(new Date(message.messageTimestamp), 'HH:mm, dd MMM yyyy');

    useEffect(() => {
        if (isUnread && messageRef.current) {
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    clientWsMessageOperate.markAsRead(message.messageId);

                    const updatedMessage = {...message, read: true};
                    dispatch(updateMessage(updatedMessage));

                    observer.disconnect();
                }
            }, {threshold: 1.0});

            observer.observe(messageRef.current);

            return () => observer.disconnect();
        }
    }, [isUnread, message, dispatch]);

    useEffect(() => {
        const fetchFilePreview = async () => {
            if (message.mediaFileId) {
                try {
                    const responseInfo = await clientWsContent.findFilesByIds([message.mediaFileId]);
                    const responseBlob = await clientWsContent.getFilePreview(message.mediaFileId);
                    const blob = new Blob([responseBlob.data], {type: responseBlob.headers['content-type']});
                    const url = URL.createObjectURL(blob);
                    setFileInfo(responseInfo.data[0]);
                    setFileBlob(blob);
                    setFileUrl(url);
                } catch (error) {
                    console.error('Error fetching file preview:', error);
                }
            }
        };

        fetchFilePreview();

        return () => {
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.mediaFileId]);

    // показываем все типы, чтобы люди жаловались, если что-то не работает
    // if (message.messageType === 'UNKNOWN') {
    //     return null;
    // }

    return (
        <div className={`flex ${isOutgoing ? 'justify-end' : 'justify-start'}`}>
            <div className={`flex flex-col max-w-[80%] ${isOutgoing ? 'items-end' : 'items-start'}`}>
                {/*<div className={channelTypeClass}>{message.channelType}</div>*/}
                <div
                    className={`relative shadow-md px-4 py-2 rounded-2xl ${isOutgoing ? 'bg-highlight/75 text-slate-800' : 'bg-slate-50/50 text-slate-800'} ${isUnread && isIncoming ? 'border-2 border-red-500' : 'border-none'}`}>

                    {message.body && (
                        <div className="mb-2 text-base leading-relaxed max-w-full">
                            {formatMessageBody(message.body)}
                        </div>
                    )}
                    <FileInfo fileInfo={fileInfo} fileBlob={fileBlob} fileUrl={fileUrl}/>
                    <div ref={messageRef}
                         className={`text-xs flex justify-between items-center space-x-2 mt-2 ${isOutgoing ? 'text-slate-500' : 'text-slate-600'} whitespace-nowrap overflow-hidden`}>
                        <div>{formattedTimestamp}</div>
                        <div>
                            {channelType === ChannelType.MULTICHAT && (
                                <Tooltip text={message.channelType || ''}>
                                    <SocialNetworkBadge
                                        network={message.channelType || ''}
                                        inverted={true}
                                        background={false}
                                        onlyLogo={true}
                                        customClass={`w-3 h-3 flex justify-center items-center`}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;
