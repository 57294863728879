import React, {useEffect, useState} from 'react';
import {clientWsChannel} from "../../../api/client-ws";
import {ConnectionStatus} from "../../types/types";
import StatusBadge from "../../atom/StatusBadge";
import SocialNetworkBadge from "../../atom/SocialNetworkBadge";
import {Link} from "react-router-dom";
import {FiExternalLink} from "react-icons/fi";
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";

interface Channel {
    channelId: string;
    channelType: string;
    title: string | null;
    status: string;
}

const ChannelsWidget: React.FC = () => {
    const [channels, setChannels] = useState<Channel[]>([]);
    const [sortField, setSortField] = useState<keyof Channel>('title');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        const loadUsersChannels = async () => {
            try {
                const response = await clientWsChannel.getAll();
                setChannels(response.data);
            } catch (error) {
                console.error('Не удалось загрузить каналы пользователя', error);
            }
        };

        loadUsersChannels();
    }, []);

    const handleSort = (field: keyof Channel) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const sortedChannels = [...channels].sort((a, b) => {
        const order = sortDirection === 'asc' ? 1 : -1;
        const fieldA = a[sortField] ?? '';
        const fieldB = b[sortField] ?? '';

        if (fieldA < fieldB) return -1 * order;
        if (fieldA > fieldB) return 1 * order;
        return 0;
    });

    if (channels.length === 0) {
        return (
            <div className="w-fit max-w-lg p-4 bg-white rounded-lg shadow-lg">
                <div className="flex items-center mb-2">
                    <Link to="/connections" className="text-lg font-semibold text-gray-800 hover:underline">
                        Нет подключений
                    </Link>
                    <Link to="/connections" className="ml-2 text-blue-500 hover:text-blue-700">
                        <FiExternalLink size={18}/>
                    </Link>
                </div>
                <Link to="/connections">
                    <Button title="Добавить первое подключение" buttonColor={ButtonColor.GREEN}
                            buttonSize={ButtonSize.FULL} customClass={`shadow`}/>
                </Link>
            </div>
        );
    }

    return (
        <div className="w-fit max-w-lg p-4">
            <div className="flex items-center mb-2">
                <Link to="/connections" className="text-lg font-semibold text-gray-800 hover:underline">
                    Подключения
                </Link>
                <Link to="/connections" className="ml-2 text-blue-500 hover:text-blue-700">
                    <FiExternalLink size={18}/>
                </Link>
            </div>
            <table className="min-w-full text-left text-sm mb-2">
                <thead>
                <tr className="border-b">
                    <th
                        className="py-2 font-medium text-gray-700 cursor-pointer"
                        onClick={() => handleSort('title')}
                    >
                        Название {sortField === 'title' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th
                        className="py-2 px-4 font-medium text-gray-700 cursor-pointer"
                        onClick={() => handleSort('channelType')}
                    >
                        Тип {sortField === 'channelType' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th
                        className="py-2 font-medium text-gray-700 cursor-pointer"
                        onClick={() => handleSort('status')}
                    >
                        Статус {sortField === 'status' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                </tr>
                </thead>
                <tbody>
                {sortedChannels.map((channel) => (
                    <tr key={channel.channelId} className="border-b">
                        <td className="p-1">{channel.title || "Без названия"}</td>
                        <td className="p-1 px-4">
                            <SocialNetworkBadge
                                network={channel.channelType}
                                short={false}
                                border={false}
                                inverted={false}
                                background={true}
                                hover={false}
                            />
                        </td>
                        <td className="p-1">
                            <StatusBadge
                                status={channel.status as ConnectionStatus}
                                background={true}
                                border={false}
                                className="shadow"
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ChannelsWidget;
