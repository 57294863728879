import React, {useEffect, useState} from 'react';
import {clientWsMessage} from "../../../api/client-ws";


const CheckRequestPage: React.FC = () => {
    const [someResponse, setSomeResponse] = useState();

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const chatIds = ['669fb48cb82c22420a73ebc0', '66b33d22613d36390c706b641'];
                const page = 0; // при необходимости
                const size = 10; // при необходимости

                const response = await clientWsMessage.findInChats(chatIds, page, size);

                setSomeResponse(response.data);
            } catch (error) {
                console.error('Error fetching:', error);
            }
        };
        fetchUserProfile();
    }, []);

    return (
        <div>
            {JSON.stringify(someResponse)}
        </div>
    );
};

export default CheckRequestPage;