import React, {ReactNode} from 'react';

interface ButtonProps {
    title?: string;
    icon?: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    buttonColor?: ButtonColor;
    buttonSize?: ButtonSize;
    customClass?: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    children?: ReactNode;
    hover?: boolean;
    shadow?: boolean;
}

export const enum ButtonColor {
    GREEN,
    GREEN_2,
    TRANSPARENT,
    SLATE,
    BLUE,
    INDIGO_BLUE,
    RED
}

export const enum ButtonSize {
    SMALL,
    NORMAL,
    MEDIUM,
    FULL,
}

const Button: React.FC<ButtonProps> = ({
                                           title, onClick,
                                           icon, buttonColor,
                                           buttonSize, customClass,
                                           disabled = false, type,
                                           hover = true,
                                           children, shadow = false
                                       }) => {
    let colorClass: string;
    let buttonSizeClass: string;
    switch (buttonColor) {
        case ButtonColor.GREEN:
            colorClass = "bg-button-green-bg text-button-green-text hover:bg-button-green-hover-bg";
            break;
        case ButtonColor.TRANSPARENT:
            colorClass = `bg-transparent text-slate-500 ${hover ? 'hover:text-slate-700 hover:bg-slate-100' : ''} `;
            break;
        case ButtonColor.SLATE:
            colorClass = "bg-slate-100 text-slate-500 hover:text-slate-700 hover:bg-slate-100";
            break;
        case ButtonColor.GREEN_2:
            colorClass = "bg-green-500 text-white hover:bg-green-600";
            break;
        case ButtonColor.BLUE:
            colorClass = "bg-blue-500 text-white hover:bg-blue-600";
            break;
        case ButtonColor.RED:
            colorClass = "bg-red-500 text-white hover:bg-red-600";
            break;
        case ButtonColor.INDIGO_BLUE:
            colorClass = "bg-gradient-to-r from-purple-500 to-indigo-500 text-white hover:bg-gradient-to-r hover:from-purple-500 hover:to-indigo-600";
            break;
        default:
            colorClass = "";
            break;
    }

    switch (buttonSize) {
        case ButtonSize.SMALL:
            buttonSizeClass = "w-6 h-8";
            break;
        case ButtonSize.NORMAL:
            buttonSizeClass = "w-8 h-8";
            break;
        case ButtonSize.MEDIUM:
            buttonSizeClass = "w-12 h-8";
            break;
        case ButtonSize.FULL:
            buttonSizeClass = "w-full px-3 h-8";
            break;
        default:
            buttonSizeClass = "";
            break;
    }

    return (
        <div className={`
                            transition-transform duration-300 transform 
                            active:translate-y-0.5
                        `}>
            <button
                onClick={onClick}
                disabled={disabled}
                type={type}
                className={`
                    ${customClass}
                    ${buttonSizeClass}
                    m-auto
                    rounded-md
                    flex items-center justify-center
                    transition-colors duration-300
                    ${colorClass}
                    focus:outline-none
                    ${shadow ? 'shadow' : ''}
                `}
            >
                {title}
                {icon}
                {children}
            </button>
        </div>
    );
};

export default Button;