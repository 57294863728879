import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from "../redux";
import {clientWsChat} from "../api/client-ws";
import {cleanChats, pushChats} from "../redux/slices/inbox-slice";

export const useFetchChats = () => {
    const dispatch: AppDispatch = useDispatch();
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    const showUnreadRedux = useSelector((state: RootState) => state.inbox.showUnread);
    const filteredChatIdRedux = useSelector((state: RootState) => state.inbox.filteredChannelId);

    // Ссылаемся на предыдущие значения для предотвращения лишних запросов
    const prevPageRef = useRef(page);
    const prevFiltersRef = useRef({showUnread: showUnreadRedux, filteredChatId: filteredChatIdRedux});

    // Функция для загрузки чатов
    const fetchChats = useCallback(async (reset: boolean = false) => {
        setLoading(true);

        if (reset) {
            dispatch(cleanChats());
            setPage(0);
        }

        const currentPage = reset ? 0 : page;

        try {
            const response = await clientWsChat.findChats(currentPage, 50, filteredChatIdRedux || undefined, showUnreadRedux);
            dispatch(pushChats(response.data.content || []));
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching chats:', error);
        } finally {
            setLoading(false);
        }
    }, [page, filteredChatIdRedux, showUnreadRedux, dispatch]);

    useEffect(() => {
        // Проверяем, изменились ли фильтры или страница
        const filtersChanged = prevFiltersRef.current.showUnread !== showUnreadRedux ||
            prevFiltersRef.current.filteredChatId !== filteredChatIdRedux;
        const pageChanged = prevPageRef.current !== page;

        if (filtersChanged || pageChanged) {
            // Обновляем refs с новыми значениями
            prevFiltersRef.current = {showUnread: showUnreadRedux, filteredChatId: filteredChatIdRedux};
            prevPageRef.current = page;

            fetchChats(filtersChanged); // Если фильтры изменились, делаем reset
        }
    }, [fetchChats, page, showUnreadRedux, filteredChatIdRedux]);

    return {page, setPage, totalPages, loading, fetchChats};
};
