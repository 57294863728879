import React, {useEffect, useRef, useState} from 'react';
import {FaPlug, FaTimesCircle} from "react-icons/fa";
import {ChannelType, Connection, ConnectionStatus} from "../../types/types";
import SocialNetworkBadge from "../../atom/SocialNetworkBadge";
import {setFilteredChannelId} from '../../../redux/slices/inbox-slice';
import {AppDispatch} from "../../../redux";
import {useDispatch} from "react-redux";
import {clientWsChannel} from "../../../api/client-ws";
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";
import StatusBadgeRounded from "../../atom/StatusBadge";
import Tooltip from "../../atom/Tooltip";

const FilterByConnection: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false); // новое состояние для анимации
    const [connections, setConnections] = useState<Connection[]>([]);
    const [filteredConnection, setFilteredConnection] = useState<Connection | null>(null);
    const dispatch: AppDispatch = useDispatch();
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchConnections();
    }, []);

    const fetchConnections = async () => {
        try {
            const response = await clientWsChannel.getAll();
            setConnections(response.data);
        } catch (error) {
            console.error('Error fetching connections:', error);
        }
    };

    const handleClick = (connection: Connection) => {
        if (connection.channelId != null) {
            dispatch(setFilteredChannelId(connection.channelId));
            setFilteredConnection(connection);
        }
        setIsOpen(false);
    };

    const handleFilterByMultiChat = () => {
        const multichat: Connection = {
            channelType: ChannelType.MULTICHAT,
            channelId: ChannelType.MULTICHAT,
            status: ConnectionStatus.ACTIVE,
        }
        dispatch(setFilteredChannelId(multichat.channelId));
        setFilteredConnection(multichat);
        setIsOpen(false);
    }

    const handleResetFilter = () => {
        dispatch(setFilteredChannelId(null));
        setFilteredConnection(null);
        setIsOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setTimeout(() => setIsAnimating(false), 300); // задержка для завершения анимации
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className="relative" ref={menuRef}>
            {/* Отображение, если filteredConnection существует (текущий выбранный канал) */}
            {filteredConnection ? (
                <div
                    className="relative inline-block transition-transform duration-300 transform active:translate-y-0.5">
                    {/* Основная кнопка для переключения открытия/закрытия меню */}
                    <div className="relative flex items-center justify-center cursor-pointer"
                         onClick={() => setIsOpen(!isOpen)}>
                        {/* Бейдж с иконкой соцсети */}
                        <SocialNetworkBadge
                            network={filteredConnection.channelType}
                            short={true}
                            inverted={false}
                            background={true}
                        />
                        {/* Бейдж статуса поверх иконки соцсети */}
                        <div className="absolute -top-2 right-7">
                            <StatusBadgeRounded
                                status={filteredConnection.status}
                                short={true}
                                rounded={true}
                                invertColor={true}
                                className="rounded-full p-1 w-10 border-4 border-white transform scale-[40%]"
                            />
                        </div>
                    </div>
                    {/* Иконка сброса фильтра (крестик), отображаемая при наведении */}
                    <FaTimesCircle
                        className="absolute -top-1 -right-1 transform duration-300 bg-white hover:text-red-500 cursor-pointer rounded-full border-2 border-white"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleResetFilter();
                        }}
                    />
                </div>
            ) : (
                /* Если filteredConnection не существует, отображается кнопка выбора подключения */
                <Tooltip text="Выбрать подключение" arrow={true}>
                    <Button
                        onClick={() => setIsOpen(!isOpen)}
                        buttonColor={ButtonColor.SLATE}
                        buttonSize={ButtonSize.MEDIUM}
                        icon={<FaPlug/>}
                    />
                </Tooltip>
            )}

            {/* Всплывающее меню со списком подключений, видимое при isOpen или isAnimating */}
            {(isOpen || isAnimating) && (
                <div
                    className={`absolute z-10 mt-2 bg-white shadow-md2 rounded-xl transition-all duration-300 transform ${
                        isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
                    }`}
                >
                    <ul>
                        {/* Если filteredConnection существует, добавляем пункт сброса фильтра */}
                        {filteredConnection ? (
                            <li
                                onClick={handleResetFilter}
                                className="px-4 py-2 hover:bg-slate-50 rounded-t-xl cursor-pointer flex items-center space-x-4 relative border-b border-b-1"
                            >
                                <Button icon={<FaTimesCircle/>} buttonSize={ButtonSize.MEDIUM}
                                        buttonColor={ButtonColor.SLATE}/>
                                <span className="flex-grow ml-12">Сбросить фильтр</span>
                            </li>
                        ) : null}

                        {/* Статичный элемент для фильтра по мультичатам */}
                        <li
                            onClick={() => handleFilterByMultiChat()}
                            className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-4 relative"
                        >
                            {/* Иконка для мультичата */}
                            <SocialNetworkBadge network="MULTICHAT" short={true} inverted={false} background={true}/>

                            {/* Заголовок элемента */}
                            <span className="flex-grow ml-4">Мультичат</span>
                        </li>

                        {/* Проход по массиву connections и отображение каждого подключения */}
                        {connections.map((connection, index) => (
                            <div className="relative flex-grow group" key={index}>
                                <li
                                    onClick={() => handleClick(connection)}
                                    className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-4 relative"
                                >
                                    {/* Бейдж с иконкой соцсети для конкретного connection */}
                                    <div className="relative">
                                        <SocialNetworkBadge
                                            network={connection.channelType}
                                            short={true}
                                            inverted={false}
                                            background={true}
                                        />
                                        {/* Бейдж статуса поверх иконки соцсети */}
                                        <div className="absolute -top-2 right-7">
                                            <StatusBadgeRounded
                                                status={connection.status}
                                                short={true}
                                                rounded={true}
                                                invertColor={true}
                                                className="rounded-full p-1 w-10 border-4 border-white transform scale-[40%]"
                                            />
                                        </div>
                                    </div>

                                    {/* Текст с названием или channelId, который отображается в одной строке */}
                                    <div className="truncate overflow-hidden max-w-40">
                                        {connection.title ? connection.title : connection.channelId}
                                    </div>
                                    {/* Всплывающее описание подключения при наведении */}
                                    <div
                                        className="absolute py-3 items-center rounded-xl pr-4 space-x-4 bg-slate-50 left-16 -top-0 z-20 hidden group-hover:block whitespace-nowrap">
                                        {connection.title ? connection.title : connection.channelId}
                                    </div>
                                </li>
                            </div>
                        ))}
                    </ul>
                </div>
            )}
        </div>

    );
};

export default FilterByConnection;