import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Login from "../components/pages/no-auth/Login";
import Signup from "../components/pages/no-auth/Signup";
import PrivateRoute from "./PrivateRoute";
import BaseLayout from "../components/pages/auth/BaseLayout";
import Dashboard from "../components/organism/Dashboard";
import Inbox from "../components/organism/Inbox";
import Contacts from "../components/organism/Contacts";
import Connections from "../components/organism/Connections";
import AddConnection from "../components/organism/addConnection/AddConnection";
import Billing from "../components/organism/Billing";
import Team from "../components/organism/Team";
import Profile from "../components/organism/Profile";
import ActivationPage from "../components/pages/auth/ActivationPage";
import SignupForTeammate from "../components/pages/no-auth/SignupForTeammate";
import WhatsAppConnection from "../components/organism/addConnection/WhatsAppConnection";
import TelegramConnection from "../components/organism/addConnection/TelegramConnection";
import AvitoConnection from "../components/organism/addConnection/AvitoConnection";
import {RoleType} from "../components/types/types";
import CheckRequestPage from "../components/pages/auth/CheckRequestPage";
import ContactsWidget from "../components/organism/ContactsWidget";

const AppRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<Login/>}/>
                <Route path='/signup' element={<Signup/>}/>
                <Route path='/signup-teammate' element={<SignupForTeammate/>}/>

                <Route path="/" element={<PrivateRoute><BaseLayout/></PrivateRoute>}>
                    <Route path="/" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                    <Route path="inbox" element={<PrivateRoute><Inbox/></PrivateRoute>}/>
                    <Route path="contacts" element={<PrivateRoute><Contacts/></PrivateRoute>}/>

                    <Route path="connections"
                           element={<PrivateRoute requiredRole={RoleType.OWNER}><Connections/></PrivateRoute>}/>
                    <Route path="connections/add-connection"
                           element={<PrivateRoute requiredRole={RoleType.OWNER}><AddConnection/></PrivateRoute>}/>
                    <Route path="connections/add-connection/whatsapp"
                           element={<PrivateRoute requiredRole={RoleType.OWNER}><WhatsAppConnection/></PrivateRoute>}/>
                    <Route path="connections/add-connection/telegram"
                           element={<PrivateRoute requiredRole={RoleType.OWNER}><TelegramConnection/></PrivateRoute>}/>
                    <Route path="connections/add-connection/avito"
                           element={<PrivateRoute requiredRole={RoleType.OWNER}><AvitoConnection/></PrivateRoute>}/>

                    <Route path="contacts-widget"
                           element={<PrivateRoute requiredRole={RoleType.OWNER}><ContactsWidget/></PrivateRoute>}/>
                    <Route path="settings/billing"
                           element={<PrivateRoute requiredRole={RoleType.OWNER}><Billing/></PrivateRoute>}/>
                    <Route path="settings/team"
                           element={<PrivateRoute requiredRole={RoleType.OWNER}><Team/></PrivateRoute>}/>
                    <Route path="settings/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
                    <Route path="checkresponse" element={<PrivateRoute><CheckRequestPage/></PrivateRoute>}/>
                </Route>

                <Route path="/activation" element={<ActivationPage/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;