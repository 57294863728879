import React from 'react';
import {getNetworkStyles} from "../../utils/styleUtils";

interface SocialNetworkBadgeProps {
    network: string;
    short?: boolean;
    inverted?: boolean;
    background?: boolean;
    onlyLogo?: boolean;
    border?: boolean;
    hover?: boolean;
    customClass?: string;
}

const SocialNetworkBadge: React.FC<SocialNetworkBadgeProps> = ({
                                                                   network,
                                                                   short = false,
                                                                   inverted = false,
                                                                   background = true,
                                                                   onlyLogo = false,
                                                                   border = false,
                                                                   hover = false,
                                                                   customClass
                                                               }) => {
    const {badgeClass, iconClass} = getNetworkStyles(network, inverted, background, border, hover);

    if (onlyLogo) {
        return (
            <div
                className={`${customClass} inline-block font-bold uppercase ${badgeClass}`}>
                {iconClass}
            </div>
        );
    }

    return (
        <div
            className={`${customClass} inline-block px-4 py-1 rounded-md 
            font-bold uppercase ${badgeClass} ${short ? 'w-12 text-center' : ''}
            transition-colors duration-300`}>
            {short ? iconClass : network}
        </div>
    );
};


export default SocialNetworkBadge;
