import React, {useEffect, useState} from 'react';
import {CurrentPlanResponse} from "../../types/types";
import {clientWsBillingPlan} from "../../../api/client-ws";
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";
import {Link} from "react-router-dom";
import {FiExternalLink} from "react-icons/fi";


const CurrentBillingPlanWidget: React.FC = () => {
    const [currentPlan, setCurrentPlan] = useState<CurrentPlanResponse>();

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const currentPlanResponse = await clientWsBillingPlan.getCurrentBillingPlan();
                setCurrentPlan(currentPlanResponse.data);
            } catch (error) {
                console.error('Error fetching current plan:', error);
            }
        };
        fetchUserProfile();
    }, []);

    if (!currentPlan) {
        return <div>План не найден</div>;
    }

    const formattedNextPayDate = new Date(currentPlan.nextPayTimestamp).toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    return (
        <div className="w-96 p-4">
            <div className={`mb-4`}>
                <div className="flex items-center mb-2">
                    <Link to="/settings/billing" className="text-lg font-semibold text-gray-800 hover:underline">
                        Текущий план: {currentPlan.billingPlanType}
                    </Link>
                    <Link to="/settings/billing" className="ml-2 text-blue-500 hover:text-blue-700">
                        <FiExternalLink size={18}/>
                    </Link>
                </div>
                <div className="text-sm text-gray-600 mb-4">
                    <p>Цена: <span className="font-medium text-gray-700">{currentPlan.price}₽</span></p>
                    <p>Дата следующего платежа: <span
                        className="font-medium text-gray-700">{formattedNextPayDate}</span>
                    </p>
                </div>

                {currentPlan.planLimits && (
                    <div className="text-xs text-gray-600 border-t pt-2 my-2">
                        <p>Лимиты:</p>
                        <ul className="list-disc list-inside">
                            <li>WhatsApp: <span
                                className="font-semibold">{currentPlan.planLimits.whatsappChannel}</span>
                            </li>
                            <li>Telegram: <span
                                className="font-semibold">{currentPlan.planLimits.telegramChannel}</span>
                            </li>
                            <li>Avito: <span className="font-semibold">{currentPlan.planLimits.avitoChannel}</span></li>
                            <li>Операторы: <span className="font-semibold">{currentPlan.planLimits.teammates}</span>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <Link to="/settings/billing">
                <Button title="Изменить план" buttonColor={ButtonColor.GREEN} buttonSize={ButtonSize.FULL}
                        customClass={`shadow`}/>
            </Link>
        </div>
    );
};

export default CurrentBillingPlanWidget;
