import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../redux';
import {setTranscribeAudioMessages, setUseEnterToSend} from '../../redux/slices/inbox-slice';
import ChatEditorHeader from '../molecula/inbox/ChatEditorHeader';
import ChatMergeOptions from '../molecula/inbox/ChatMergeOptions';
import ContentWrapper from '../atom/ContentWrapper';
import PollingInbox from '../molecula/inbox/PollingInbox';
import InboxSettingsModal from '../molecula/inbox/InboxSettingsModal';
import {clientWsUserProfile} from '../../api/client-ws';
import ChatsToolbar from "../molecula/inbox/ChatsToolbar";
import Chats from "../molecula/inbox/Chats";
import MessagesToolbar from "../molecula/inbox/MessagesToolbar";
import Messages from "../molecula/inbox/Messages";
import MessagesFooter from "../molecula/inbox/MessagesFooter";

const Inbox: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const multiChatSettingsVisible = useSelector((state: RootState) => state.inbox.multiChatSettingsVisible);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await clientWsUserProfile.load();
                dispatch(setUseEnterToSend(response.data.settings.useEnterToSend));
                dispatch(setTranscribeAudioMessages(response.data.settings.transcribeAudioMessages));
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
        fetchUserProfile();
    }, [dispatch]);

    return (
        <>
            <PollingInbox/>
            <InboxSettingsModal/>
            <ContentWrapper>
                <div className="flex h-screen overflow-hidden">
                    <div className="flex flex-col flex-1 w-0">
                        <ChatsToolbar/>
                        <Chats/>
                    </div>
                    <div className="flex flex-2 flex-col shadow-lg">
                        <MessagesToolbar/>
                        <Messages/>
                        <MessagesFooter/>
                    </div>

                    {/* Условный рендеринг панели с анимацией */}
                    {multiChatSettingsVisible && (
                        <div
                            className={`flex flex-col flex-1 transition-opacity duration-300 ease-in-out ${
                                multiChatSettingsVisible ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            <ChatEditorHeader/>
                            <ChatMergeOptions/>
                        </div>
                    )}
                </div>
            </ContentWrapper>
        </>
    );
};

export default Inbox;
