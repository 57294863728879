import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {IconType} from 'react-icons';

interface SidebarLinkProps {
    to: string;
    Icon?: IconType;
    iconClass?: string;
    title: string;
    onClick?: () => void;
    className?: string;
    target?: string;
    rel?: string;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({
                                                     to, Icon, iconClass,
                                                     title, onClick, className
                                                     , target, rel
                                                 }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <Link
            to={to}
            target={target}
            rel={rel}
            onClick={onClick}
            className={`flex items-center mb-1 px-4 py-3 text-lg cursor-pointer rounded-md transition-colors duration-200 ${
                isActive
                    ? 'bg-slate-200 text-slate-700'
                    : 'text-slate-500 hover:bg-slate-100 hover:text-slate-700'
            } ${className || ''}`}
            aria-current={isActive ? 'page' : undefined}
        >
            {Icon ? (
                <Icon className="mr-2 text-xl"/>
            ) : iconClass ? (
                <div className={`${iconClass} mr-2`}/>
            ) : (
                <div className="mr-2"/>
            )}
            <span>{title}</span>
        </Link>
    );
};

export default React.memo(SidebarLink);
