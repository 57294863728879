import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from "../../../redux";
import {clientWsMessage} from "../../../api/client-ws";
import {pushMessages, setMessages, setReloadMessages} from "../../../redux/slices/inbox-slice";
import Message from './Message';
import {ChannelType, MessageInterface} from "../../types/types";

const Messages: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const messages = useSelector((state: RootState) => state.inbox.messages);
    const reloadMessages = useSelector((state: RootState) => state.inbox.reloadMessages);
    const selectedChat = useSelector((state: RootState) => state.inbox.selectedChat);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);

    useEffect(() => {
        if (selectedChat?.chatId) {
            resetChatState();
            loadMessages(true);
            dispatch(setReloadMessages(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChat?.chatId, reloadMessages]);

    const resetChatState = () => {
        dispatch(setMessages([]));
        setPage(0);
        setHasMoreMessages(true);
    };

    const loadMessages = async (isInitialLoad = false) => {
        if (loading || !selectedChat?.chatId) return;

        setLoading(true);
        const currentPage = isInitialLoad ? 0 : page;

        try {
            let response;

            if (selectedChat.channelType !== ChannelType.MULTICHAT) {
                response = await clientWsMessage.findInChat(selectedChat.chatId, currentPage, 10);
            } else {
                // Теперь joinedChats содержит объекты ChatInterface, из которых извлекаем chatId
                const chatIds = selectedChat.multiChatSettings?.joinedChats.map(joinedChat => joinedChat.chatId) || [];

                // Проверка, что массив chatIds не пуст
                if (chatIds.length > 0) {
                    response = await clientWsMessage.findInChats(chatIds, currentPage, 10);
                } else {
                    console.warn("Нет доступных chatIds в multiChatSettings.joinedChats для MULTICHAT");
                    setHasMoreMessages(false);
                    return;
                }
            }

            if (response?.data?.content?.length) {
                const container = containerRef.current;
                if (container) {
                    const previousScrollHeight = container.scrollHeight;
                    const previousScrollTop = container.scrollTop;

                    dispatch(pushMessages(response.data.content));

                    // Используем requestAnimationFrame для предотвращения подергиваний
                    requestAnimationFrame(() => {
                        const newScrollHeight = container.scrollHeight;
                        const addedHeight = newScrollHeight - previousScrollHeight;
                        container.scrollTop = previousScrollTop + addedHeight;
                    });

                    // Проверка на наличие дополнительных сообщений
                    if (response.data.content.length < 10) {
                        setHasMoreMessages(false);
                    } else {
                        setPage(currentPage + 1);
                    }
                }
            } else {
                setHasMoreMessages(false);
            }
        } catch (error) {
            console.error("Ошибка при загрузке сообщений:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleScroll = () => {
        const container = containerRef.current;
        if (container && container.scrollTop === 0 && hasMoreMessages && !loading) {
            loadMessages();
        }
    };

    return (
        <div
            className="flex-1 overflow-y-auto p-4 space-y-4"
            ref={containerRef}
            onScroll={handleScroll}
        >
            {messages.map((message: MessageInterface) => (
                <Message key={message.messageId} message={message} channelType={selectedChat?.channelType}/>
            ))}
            {loading && <div className="loading-indicator">Загрузка...</div>}
        </div>
    );
};

export default Messages;
